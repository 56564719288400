<script setup>
import { defineProps } from "vue"
import { useI18n } from "vue-i18n"
const { t } = useI18n()

const props = defineProps({
    cylinderConnected: {
        type: Boolean,
        required: true,
    },
    actionCylinder: {
        type: String,
    },
})

function getTitle() {
    return props.actionCylinder == "replace"
        ? t("kibolt.insert_replacement_cylinder")
        : t("kibolt.insert_cylinder")
}
</script>
<template>
    <div>
        <h2>2. {{ getTitle() }}</h2>
        <p v-if="!cylinderConnected" class="text-base statut-key not-connected">
            {{ $t("kibolt.wait_seconds_detected_application") }}<br /><br />
            {{ $t("kibolt.connected_cylinder_programmer_but_nothing_happen") }}
            <br />
            <a href="http://home.cogelec.fr/v2/#/setup">
                {{ $t("CLICK_HERE") }}</a
            >.
        </p>
        <p v-else class="text-base statut-key connected">
            {{ $t("kibolt.cylinder_detected") }}
        </p>
    </div>
</template>

<style lang="scss">
@import "@/assets/scss/kibolt/step.scss";
@import "@/assets/scss/global.scss";
@import "@/assets/scss/variables/fontSize.scss";

h2 {
    font-family: $font_avenir_heavy;
    font-size: $semiBig;
}
</style>
